import { RouteComponentProps } from "@reach/router"
import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../redux/store"
import { actions } from "../../../redux/actions"

const mapState = (state: RootState, props) => {
  return {}
}

const mapDispatch = (dispatch: any, props: RouteComponentProps) => ({
  onMount: () => {
    dispatch(actions.play.reset())
  },
  onLocked: () => {
    dispatch(actions.modal.unlockOpen())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
