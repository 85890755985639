import React, { ReactElement } from "react"

type Props = {
  to?: string
  title: string
  icon?: ReactElement
  onClick: Function
  color?: string
}

export const Item: React.FC<Props> = props => (
  <span
    onClick={() => props.onClick()}
    className="-m-3 p-3 py-4 flex items-center space-x-4 rounded-lg hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer"
  >
    {props.icon && (
      <div className={`flex-shrink-0 flex items-center text-green-600`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-6 w-6"
        >
          {props.icon}
        </svg>
      </div>
    )}
    <div className="text-base leading-6 font-medium text-gray-800">
      {props.title}
    </div>
  </span>
)
