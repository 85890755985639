import { RouteComponentProps } from "@reach/router"
import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props) => ({
  matrix: state.play.actual?.matrix,
  size: state.play.actual?.size,
  win: state.play.win,
  isEnd: state.tutorial.end,
  steps: state.tutorial.steps,
  current: state.tutorial.current,
})

const mapDispatch = (dispatch: any, props: RouteComponentProps) => ({
  onMount: () => {
    dispatch(actions.play.selectGame({ id: "4x4.1" }))
    dispatch(actions.sounds.play("begin"))
  },
  onUnmount: () => {
    dispatch(actions.tutorial.reset())
  },
  onNext: () => {
    dispatch(actions.tutorial.next())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
