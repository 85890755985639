import React, { useEffect } from "react"
import { GameHeaderBar } from "../GameHeaderBar/GameHeaderBar"
import { Success } from "../Success/Success"
import { connector, ContainerProps } from "./containers/Tutorial.containers"
import { RouteComponentProps } from "@reach/router"
import { GameEntity } from "../../entities/GameEntity"
import { FormattedMessage } from "react-intl"

import { Grid } from "../Grid/Grid"
import { navigate } from "gatsby"
import { Transition } from "@headlessui/react"
import { getSelection } from "../../utils/urls"

type Props = {
  matrix: GameEntity["matrix"]
  size: string
  onMount: Function
  onUnmount: Function
  onNext: Function
  current: number
  isEnd: boolean
  steps: Array<{ text: string }>
} & RouteComponentProps

const TutorialWrapper: React.FC<Props> = props => {
  useEffect(() => {
    props.onMount()

    return () => props.onUnmount()
  }, [])

  return (
    <div className="min-h-screen bg-gray-100">
      <GameHeaderBar to="/app/" />

      <div
        className="fixed top-0 bottom-0 left-0 right-0 z-10"
        onClick={() => props.onNext()}
      ></div>

      <div className="container relative flex justify-center max-w-sm m-auto sm:items-center">
        <div className="w-full">
          <div className="flex justify-center">
            <div className="w-full">
              {props.matrix && <Grid matrix={props.matrix} size={props.size} />}
            </div>
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 left-0 right-0 w-full">
        <div className="container relative flex items-center justify-center m-auto">
          <div className="absolute bottom-0 w-full max-w-lg text-xl font-bold text-gray-700">
            {props.steps.map(({ text }, index) => (
              <Transition
                key={index}
                show={index === props.current}
                className="absolute bottom-0 left-0 right-0 w-full px-2 pt-4 pb-8 pl-4 text-center transition ease-in-out transform"
                enter="duration-500"
                enterFrom="translate-y-10 opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="duration-300"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="-translate-y-10 opacity-0"
              >
                <FormattedMessage id={text} />
              </Transition>
            ))}
            <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center w-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-6 h-6 animate-bounce"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <Success
        isLoading={false}
        isOpen={props.isEnd}
        title={<FormattedMessage id="tutorial.success.title" />}
        description={<FormattedMessage id="tutorial.success.description" />}
        onSubmit={() => navigate(getSelection())}
        label={{
          submit: <FormattedMessage id="tutorial.success.submit" />,
        }}
      />
    </div>
  )
}

export const TutorialContainer: React.FC<
  ContainerProps & RouteComponentProps
> = props => <TutorialWrapper {...props} />

export const Tutorial = connector(TutorialContainer)
