import React from "react"
import { Router } from "@reach/router"
import { Seo } from "../components/Seo/Seo"
import { App } from "../components/App/App"
import { Drawer } from "../components/Drawer/Drawer"
import { CTA } from "../components/CTA/CTA"
import { GameMode } from "../components/pages/GameMode"
import { Classical } from "../components/pages/Classical"
import { Tutorial } from "../components/pages/Tutorial"
import { Play } from "../components/pages/Play"
import { LockedModal } from "../components/LockedModal/LockedModal"

export default props => (
  <>
    <Seo
      title="Futoshiki"
      description=""
      lang="en"
      defaultLangUrl="/app/"
      langUrls={[]}
    />
    <App>
      <Router basepath="/app/">
        <GameMode path="/" />
        <Classical path="/classical/" />
        <Tutorial path="/tutorial/" />
        <Play path="/play/:id/" />
      </Router>
      <Drawer />
      <CTA />
      <LockedModal />
    </App>
  </>
)
