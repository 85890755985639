import { RouteComponentProps } from "@reach/router"
import { connect, ConnectedProps } from "react-redux"
import { RootState } from "../../../redux/store"
import { actions } from "../../../redux/actions"
import { chunk } from "lodash"

const mapState = (state: RootState, props) => {
  const games = Array.from(state.play.games.values())

  return {
    games: chunk(games, 40),
    history: state.play.history,
    levelChoosing: state.play.levelChoosing,
    beforeLevelChoosing: state.play.beforeLevelChoosing,
  }
}

const mapDispatch = (dispatch: any, props: RouteComponentProps) => ({
  onMount: () => {
    dispatch(actions.play.reset())
  },
  onNext: () => {
    dispatch(actions.play.nextChoose())
  },
  onPrevious: () => {
    dispatch(actions.play.previousChoose())
  },
  onLocked: () => {
    dispatch(actions.modal.unlockOpen())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
