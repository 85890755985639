import React, { useEffect } from "react"
import { connector, ContainerProps } from "./containers/Classical.containers"
import { RouteComponentProps } from "@reach/router"
import { GameEntity } from "../../entities/GameEntity"
import { HistoryEntity } from "../../entities/HistoryEntity"
import { Transition } from "@headlessui/react"
import { FormattedMessage } from "react-intl"
import { AudioBtn } from "../AudioBtn/AudioBtn"
import { getPlay } from "../../utils/urls"
import { Header } from "../Header/Header"
import { Wrapper as BottomBarAction } from "../../modules/authentication/components/BottomBarAction"
import { BackgroundShapes } from "../../modules/authentication/components/BackgroundShapes"

type Props = {
  games: GameEntity[][]
  history: Map<HistoryEntity["id"], HistoryEntity>
  onMount: Function
  levelChoosing: number
  beforeLevelChoosing: number
  onNext: Function
  onPrevious: Function
  onLocked: Function
} & RouteComponentProps

const getLabel = (level: number) => {
  if (level === 0) return "levels.very.easy"
  if (level === 1) return "levels.easy"
  if (level === 2) return "levels.medium"
  if (level === 3) return "levels.hard"
  if (level === 4) return "levels.very.hard"
  if (level === 5) return "levels.pro"
  throw new Error(`LevelDoesNotExists(${level})`)
}

const getDate = ms => {
  const minutes = Math.floor(ms / 1000 / 60)
  const seconds = Math.floor(ms / 1000)

  if (seconds < 60) return seconds + "s"
  return minutes + "m" + (seconds % 60)
}

const ClassicalWrapper: React.FC<Props> = ({
  history,
  onMount,
  games,
  levelChoosing,
  beforeLevelChoosing,
  onNext,
  onPrevious,
  onLocked,
}) => {
  useEffect(() => {
    onMount()
  }, [])

  const fromLeft = {
    enterFrom: "opacity-0 translate-x-24",
    enterTo: "opacity-100 translate-x-0",
    leaveFrom: "opacity-100 translate-x-0",
    leaveTo: "opacity-0 -translate-x-24",
  }

  const fromRight = {
    enterFrom: "opacity-0 -translate-x-24",
    enterTo: "opacity-100 translate-x-0",
    leaveFrom: "opacity-100 translate-x-0",
    leaveTo: "opacity-0 translate-x-24",
  }

  const gameUrl = getPlay()

  return (
    <>
      <Header to="/app/" fixed />
      <div className="relative min-h-screen overflow-hidden bg-gray-100">
        <BackgroundShapes step={levelChoosing} />
        {games.map((levels, index) => (
          <Transition
            key={index}
            className="absolute w-full transition duration-300 ease-in-out transform"
            show={levelChoosing === index}
            {...(levelChoosing <= beforeLevelChoosing ? fromRight : fromLeft)}
          >
            <div className="container flex justify-center max-w-screen-md min-h-screen m-auto md:items-center">
              <div>
                <div className="flex flex-wrap items-start justify-center mt-10 md:-mt-20">
                  <div className="w-full py-10 text-lg font-semibold text-center text-gray-500 uppercase">
                    <FormattedMessage id={getLabel(index)} />
                  </div>
                  <div className="flex flex-wrap justify-center mx-auto">
                    {levels.map((game, index) => {
                      const meta = history && history.get(game.id)

                      return (
                        <div className="relative p-1 sm:p-3" key={game.id}>
                          {meta && meta.succeed && (
                            <div className="absolute left-0 right-0 z-10 flex justify-center w-full top-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="w-4 h-4 text-yellow-500"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                              </svg>
                            </div>
                          )}

                          <AudioBtn
                            name={"cursor"}
                            as={game.locked ? "button" : "link"}
                            to={`${gameUrl}${game.id}/`}
                            {...(game.locked
                              ? { onClick: () => onLocked() }
                              : {})}
                            className={`transform leading-3 h-12 w-12 flex flex-col text-center font-bold items-center justify-center rounded-md transition duration-300 ease-in-out ${
                              game.locked
                                ? "cursor-not-allowed bg-gray-200 text-gray-500"
                                : "shadow-sm border hover:shadow-none border-gray-200 cursor-pointer bg-white text-gray-800"
                            }`}
                          >
                            <div className="py-2">{index + 1}</div>

                            {meta && (
                              <div className={`text-xs text-gray-500`}>
                                {getDate(meta.duration)}
                              </div>
                            )}
                          </AudioBtn>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        ))}
      </div>
      <div className="fixed bottom-0 left-0 right-0 w-full">
        <BottomBarAction
          current={levelChoosing}
          steps={6}
          previous={levelChoosing > 0}
          next={levelChoosing < 5}
          loading={false}
          hidden={false}
          onNext={() => onNext()}
          onPrevious={() => onPrevious()}
        />
      </div>
    </>
  )
}

export const ClassicalContainer: React.FC<ContainerProps> = props => (
  <ClassicalWrapper {...props} />
)

export const Classical = connector(ClassicalContainer)
