import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props: any) => {
  const size = state.play.actual?.size
    ? Number(state.play.actual?.size.split("x")[0])
    : 0

  return {
    isInDraftMode: state.play.draftMode,
    size,
  }
}

const mapDispatch = (dispatch: any) => ({
  onClick: (value: number, draftMode) => {
    if (draftMode)
      return dispatch(
        actions.play.changeDraft({
          value: value ? Number(value) : value,
        })
      )

    dispatch(
      actions.play.fetchChangeCel({
        value: value ? Number(value) : value,
      })
    )
  },
  onDraft: () => {
    dispatch(actions.play.toggleDraftMode())
  },
  onUndo: () => {
    dispatch(actions.play.undo())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
