import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { startHotjar } from "../../../services/hotjar"

const mapDispatch = (dispatch: any) => ({
  onMount: () => {
    dispatch(actions.sounds.init())
    dispatch(actions.play.fetchHistory())
    dispatch(actions.play.load())
    startHotjar()
  },
})

export const connector = connect(null, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
