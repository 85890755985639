import React from "react"
import { connector, ContainerProps } from "./containers/GameHeaderBar.container"
import { AudioBtn } from "../AudioBtn/AudioBtn"

export type GameHeaderBarProps = {
  to?: string
  fixed?: boolean
}

export const GameHeaderBarWrapper: React.FC<GameHeaderBarProps> = ({
  to,
  fixed,
}) => {
  return (
    <>
      <div
        className={`${fixed ? "fixed" : "relative"} top-0 left-0 w-full z-20`}
      >
        <div className="relative">
          <div className="px-4 md:px-6">
            <div className="relative flex items-center py-4">
              <div className="relative flex">
                {to && (
                  <AudioBtn
                    as="link"
                    name="close"
                    to={to}
                    className="block p-2 text-gray-400 transition-colors duration-300 ease-out rounded hover:bg-gray-200 hover:text-gray-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </AudioBtn>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const GameHeaderBarContainer: React.FC<ContainerProps> = props => (
  <GameHeaderBarWrapper {...props} />
)

export const GameHeaderBar = connector(GameHeaderBarContainer)
