import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState) => ({
  isOpen: state.modal.unlock.isOpen,
})

const mapDispatch = (dispatch: any) => ({
  onClose: () => dispatch(actions.modal.unlockClose()),
  onSubmit: (email: string) => dispatch(actions.modal.unlock.submit(email)),
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
