import React from "react"
import { FormattedMessage } from "react-intl"
import { connector, ContainerProps } from "./containers/Controls.containers"

const Key = ({ value, active, onClick }) => (
  <div
    className={`h-16 w-16 ${
      active
        ? "bg-white hover:bg-blue-50 cursor-pointer"
        : "bg-gray-100 cursor-not-allowed text-gray-400"
    } border-gray-200 flex items-center justify-center text-2xl`}
    style={{
      borderRightWidth: "1px",
      borderTopWidth: "1px",
    }}
    key={value}
    onClick={() => active && onClick()}
  >
    {value}
  </div>
)

const Control = ({ icon, label, isActive = false, onClick = () => false }) => (
  <div
    onClick={() => onClick()}
    className={`${
      isActive ? "bg-blue-50 shadow-2xl relative z-10 ring-1 scale-110" : ""
    } transform transition duration-300 ease-in-out h-16 w-full flex items-center justify-center text-gray-600 border-gray-200 bg-white cursor-pointer `}
    style={{
      borderRightWidth: "1px",
      borderTopWidth: "1px",
    }}
  >
    <div>
      <div className="flex justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-7 w-7"
        >
          {icon}
        </svg>
      </div>
      <div className="text-center text-xs">{label}</div>
    </div>
  </div>
)

type Props = {
  onClick: Function
  onDraft: Function
  onUndo: Function
  isInDraftMode: boolean
  size: number
}

export const ControlsWrapper: React.FC<Props> = props => {
  return (
    <div>
      <div
        className="grid grid-cols-3 border-gray-200"
        style={{
          borderLeftWidth: "1px",
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(value => (
          <Key
            active={value <= props.size}
            value={value}
            key={value}
            onClick={() => props.onClick(value, props.isInDraftMode)}
          />
        ))}
      </div>
      <div
        className="grid grid-cols-1 border-gray-200"
        style={{
          borderLeftWidth: "1px",
        }}
      >
        <Control
          onClick={() => props.onDraft()}
          isActive={props.isInDraftMode}
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          }
          label={<FormattedMessage id="play.controls.notes" />}
        />
      </div>
      <div
        className="grid grid-cols-2 border-gray-200"
        style={{
          borderLeftWidth: "1px",
          borderBottomWidth: "1px",
        }}
      >
        <Control
          onClick={() => props.onUndo()}
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
            />
          }
          label={<FormattedMessage id="play.controls.undo" />}
        />
        <Control
          onClick={() => props.onClick(null)}
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"
            />
          }
          label={<FormattedMessage id="play.controls.erase" />}
        />
      </div>
    </div>
  )
}

export const ControlsContainer: React.FC<ContainerProps> = props => (
  <ControlsWrapper {...props} />
)

export const Controls = connector(ControlsContainer)
