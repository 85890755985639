import React from "react"
import {
  connector as SigninConnector,
  ContainerProps as SigninContainerProps,
} from "./signin.container"
import {
  connector as ForgotValidationConnector,
  ContainerProps as ForgotValidationContainerProps,
} from "./forgot-validation.container"
import {
  connector as SignupConnector,
  ContainerProps as SignupContainerProps,
} from "./signup.container"
import {
  connector as ForgotConnector,
  ContainerProps as ForgotContainerProps,
} from "./forgot.container"
import { FormattedMessage } from "react-intl"
import { Audio } from "../../../../components/AudioBtn/AudioBtn"

export type Props = {
  current: number
  steps: number
  previous: boolean
  focus: boolean
  loading: boolean
  hidden: boolean
  next: boolean
  onNext: () => void
  onPrevious: () => void
}

const Step = (props: { state: "done" | "active" | "empty"; label: string }) => (
  <li className="relative w-2.5 h-2.5 flex justify-center items-center">
    <span
      className={`relative z-10 w-2.5 h-2.5 transition-all duration-300 ease-in-out rounded-full ${
        props.state === "done" && "bg-blue-400 hover:bg-blue-600 cursor-pointer"
      } ${props.state === "active" && "bg-blue-400"} ${
        props.state === "empty" && "bg-gray-200"
      }`}
    ></span>

    <span className="absolute flex w-5 h-5 p-px" aria-hidden="true">
      <span
        className={`w-full h-full transition-all duration-300 ease-in-out rounded-full ${
          props.state === "active" && "bg-blue-200 animate-pulse"
        } `}
      ></span>
    </span>
  </li>
)

export const Wrapper: React.FC<Props> = props => {
  return (
    <div
      className={`fixed z-20 transform transition-all duration-300 ease-in-out ${
        props.focus ? "translate-y-full sm:translate-y-0" : ""
      } ${
        props.hidden ? "translate-y-full sm:translate-y-full" : ""
      } bottom-0 left-0 right-0 p-6 xs:p-2 xs:py-6 w-full flex flex-shrink-0 overflow-hidden`}
    >
      <Audio name="cursor">
        <div
          onClick={() => props.previous && props.onPrevious()}
          className={`${
            !props.previous
              ? "text-gray-300 cursor-not-allowed"
              : "text-gray-500 hover:bg-gray-200 hover:text-gray-600"
          } rounded transition-all duration-300 ease-in-out cursor-pointer uppercase -ml-6 xs:ml-0 sm:-ml-0 p-3 px-6 xs:px-3 text-sm font-semibold whitespace-nowrap`}
        >
          <FormattedMessage id="choose.buttons.previous" />
        </div>
      </Audio>

      <nav
        className="items-center justify-center hidden w-full md:flex"
        aria-label="Progress"
      >
        <ol className="flex items-center ml-8 space-x-5">
          {Array.from({ length: props.steps }).map((value, index) => (
            <Step
              key={index}
              state={
                props.current < index
                  ? "empty"
                  : props.current === index
                  ? "active"
                  : "done"
              }
              label="Étape 2"
            />
          ))}
        </ol>
      </nav>

      <div className="relative flex-shrink-0 ml-auto">
        <div className="group">
          <Audio name="cursor">
            <div
              data-testid="authentication/next"
              onClick={props.onNext}
              className={`transform relative z-10 group flex cursor-pointer items-center uppercase h-11 ${
                props.loading ? "w-28" : "w-36"
              } ${
                !props.next
                  ? "bg-transparent text-gray-300 cursor-not-allowed"
                  : "bg-blue-500 text-white transition-all duration-300 ease-in-out group-hover:translate-y-0.5"
              } rounded text-sm font-semibold justify-center`}
            >
              {!props.loading && (
                <>
                  <FormattedMessage id="choose.buttons.next" />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 ml-3 transition-all duration-300 ease-in-out transform group-hover:translate-x-3"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </>
              )}

              {props.loading && (
                <>
                  <div className="w-2 h-2 mx-1 bg-white rounded-full animate-bounceHarder"></div>
                  <div
                    className="w-2 h-2 mx-1 bg-white rounded-full animate-bounceHarder"
                    style={{ animationDelay: "50ms" }}
                  ></div>
                  <div
                    className="w-2 h-2 mx-1 bg-white rounded-full animate-bounceHarder"
                    style={{ animationDelay: "100ms" }}
                  ></div>
                </>
              )}
            </div>

            {props.next && (
              <div className="absolute left-0 right-0 w-full transition-all duration-300 ease-in-out transform scale-100 translate-y-1/2 select-none -bottom-2 group-hover:scale-110">
                <div
                  className="w-20 h-20 mx-auto transition-all duration-300 ease-in-out transform bg-gray-300 rounded-full group-hover:bg-gray-400"
                  style={{
                    transform: "rotateX(85deg)",
                  }}
                ></div>
              </div>
            )}
          </Audio>
        </div>
      </div>
    </div>
  )
}

export const Container: React.FC<
  SigninContainerProps | SignupContainerProps | ForgotContainerProps
> = props => <Wrapper {...props} />

export const SigninBottomBarAction = SigninConnector(Container)
export const SignupBottomBarAction = SignupConnector(Container)
export const ForgotBottomBarAction = ForgotConnector(Container)
export const ForgotValidationBottomBarAction = ForgotValidationConnector(
  Container
)
