import React from "react"
import { FormattedMessage } from "react-intl"
import {
  connector,
  ContainerProps,
} from "../Controls/containers/Controls.containers"

type Props = {
  onClick: Function
  onDraft: Function
  onUndo: Function
  isInDraftMode: boolean
  size: number
}

const Key = ({ active, value, onClick }) => (
  <div
    onClick={() => active && onClick()}
    className={`h-16 border-l w-full items-center justify-center text-2xl cursor-pointer text-gray-600 ${
      !active ? "hidden" : "flex"
    }`}
  >
    {value}
  </div>
)

const Control = ({
  icon,
  label,
  onClick = () => false,
  isActive = false,
  border = false,
}) => (
  <div
    className={`h-16 w-full flex items-center justify-center bg-white transform transition duration-300 ease-in-out cursor-pointer text-gray-900 ${
      isActive ? "bg-blue-50 shadow-2xl relative z-10 ring-1 scale-110" : ""
    } ${border ? "border-l border-gray-200" : ""}`}
    onClick={() => onClick()}
  >
    <div>
      <div className="flex justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-6 w-6 text-gray-500"
        >
          {icon}
        </svg>
      </div>
      <div className="text-center text-sm">{label}</div>
    </div>
  </div>
)

export const ControlsMobileWrapper: React.FC<Props> = props => {
  return (
    <div>
      <div className="grid grid-cols-3 border border-gray-200 bg-white shadow-2xl">
        <Control
          onClick={() => props.onUndo()}
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0019 16V8a1 1 0 00-1.6-.8l-5.333 4zM4.066 11.2a1 1 0 000 1.6l5.334 4A1 1 0 0011 16V8a1 1 0 00-1.6-.8l-5.334 4z"
            />
          }
          label={<FormattedMessage id="play.controls.undo" />}
        />
        <Control
          onClick={() => props.onDraft()}
          border
          isActive={props.isInDraftMode}
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
            />
          }
          label={<FormattedMessage id="play.controls.notes" />}
        />
        <Control
          onClick={() => props.onClick(null)}
          border
          icon={
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"
            />
          }
          label={<FormattedMessage id="play.controls.erase" />}
        />
      </div>
      <div className="flex bg-white">
        {Array.from({ length: props.size }).map((v, value) => (
          <Key
            value={value + 1}
            onClick={() => props.onClick(value + 1, props.isInDraftMode)}
            key={value + 1}
            active={value + 1 <= props.size}
          />
        ))}
      </div>
    </div>
  )
}

export const ControlsMobileContainer: React.FC<ContainerProps> = props => (
  <ControlsMobileWrapper {...props} />
)

export const ControlsMobile = connector(ControlsMobileContainer)
