import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"

const mapState = (state: RootState, props) => {
  const form = state.auth.flows.forgot.form

  return {
    current: state.auth.flows.forgot.steps.current,
    steps: state.auth.flows.forgot.steps.number,
    next: true,
    succeed: state.auth.flows.forgot.process.succeed,
    previous: state.auth.flows.forgot.steps.current > 0,
    loading: state.auth.flows.forgot.process.fetching,
    hidden: false,
    focus: form.email.focus,
  }
}

const mapDispatch = (dispatch: any, props) => ({
  onNext: () => {
    dispatch(actions.auth.flows.forgot.steps.fetchNext())
  },
  onPrevious: () => {
    dispatch(actions.auth.flows.forgot.steps.previous())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
