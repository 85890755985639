import React, { useEffect } from "react"
import { Navigation } from "../Marketing"
import { connector, ContainerProps } from "./containers/GameMode.containers"
import { RouteComponentProps } from "@reach/router"
import { FormattedMessage } from "react-intl"
import { AudioBtn } from "../AudioBtn/AudioBtn"
import { getChallenge, getSelection, getTutorial } from "../../utils/urls"

import { Header } from "../Header/Header"
import { BackgroundShapes } from "../../modules/authentication/components/BackgroundShapes"

type Props = {
  onMount: Function
} & RouteComponentProps

const Button: React.FC<{
  to?: string
  stars?: boolean
  disabled?: boolean
  onClick?: () => void
}> = props => (
  <AudioBtn
    as={"link" as "link"}
    to={props.to}
    {...(props.onClick ? { onClick: props.onClick } : {})}
    className={`flex px-4 mt-4 ${
      props.stars ? "justify-between" : "justify-center"
    } ${
      props.disabled
        ? "border-none bg-gray-200 cursor-not-allowed text-gray-500 shadow-none"
        : "bg-white"
    } uppercase w-full leading-3 border h-12 text-center font-bold items-center rounded-md transition duration-300 ease-in-out shadow-sm hover:shadow-none border-gray-200 cursor-pointer text-gray-800`}
    name={"cursor" as "cursor"}
  >
    {props.children}
  </AudioBtn>
)

const GameModeWrapper: React.FC<Props> = ({ onMount, onLocked }) => {
  useEffect(() => {
    onMount()
  }, [])

  return (
    <>
      <Header fixed hidenBack />

      <div className="relative min-h-screen overflow-hidden bg-gray-100">
        <div className="absolute z-10 w-full">
          <div className="container flex justify-center max-w-screen-md min-h-screen m-auto md:items-center">
            <div>
              <div className="flex flex-wrap items-start justify-center mt-10 md:-mt-20">
                <div className="w-full py-10 text-lg font-semibold text-center text-gray-500 uppercase">
                  <FormattedMessage id="choose.mode.title" />
                </div>

                <div className="w-full px-9">
                  <Button to={getTutorial()} stars>
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-4 text-yellow-500"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <FormattedMessage id="choose.tutorial" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-4 text-yellow-500"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    </>
                  </Button>

                  <Button to={getSelection()}>
                    <FormattedMessage id="choose.mode.grids" />
                  </Button>

                  <Button disabled onClick={onLocked}>
                    <FormattedMessage id="choose.mode.challenge" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BackgroundShapes step={0} />
      </div>
    </>
  )
}

export const GameModeContainer: React.FC<ContainerProps> = props => (
  <GameModeWrapper {...props} />
)

export const GameMode = connector(GameModeContainer)
