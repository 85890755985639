import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"

const mapDispatch = (
  dispatch: any,
  props: { playSoundWhenOpen?: boolean }
) => ({
  onOpen: () => {
    if (props.playSoundWhenOpen) dispatch(actions.sounds.play("success"))
  },
})

export const connector = connect(null, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
