import React from "react"
import { connector, ContainerProps } from "./containers/Header.container"
import { AudioBtn } from "../AudioBtn/AudioBtn"
import { getSelection } from "../../utils/urls"

export type HeaderProps = {
  to?: string
  fixed?: boolean
  onOpen: Function
  hidenBack?: boolean
}

const Heart = ({ empty = false }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 24 24"
    className={`h-6 w-6 ${empty ? "text-gray-300" : "text-red-500"}`}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
    />
  </svg>
)

export const HeaderWrapper: React.FC<HeaderProps> = ({
  to,
  fixed,
  hidenBack = false,
}) => {
  const back = to || getSelection()

  return (
    <>
      <div
        className={`${fixed ? "fixed" : "relative"} top-0 left-0 w-full z-10`}
      >
        <div className="relative">
          <div className="px-4 md:px-6">
            <div className="relative flex items-center py-4">
              {!hidenBack && (
                <div className="relative flex">
                  <AudioBtn
                    name="close"
                    as="link"
                    to={back}
                    className="block p-2 text-gray-400 transition-colors duration-300 ease-out rounded hover:bg-gray-200 hover:text-gray-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </AudioBtn>
                </div>
              )}
              {/* <div className="relative flex ml-auto">
                <Heart />
                <Heart empty />
                <Heart empty />
              </div> */}
              {/* <div className="pl-4">
                <button
                  onClick={() => onOpen()}
                  className="flex text-sm bg-gray-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                  id="user-menu"
                  aria-haspopup="true"
                >
                  <span className="sr-only">Open user menu</span>
                  <div
                    className="w-10 h-10 rounded-full"
                    style={{
                      backgroundImage: `url(/assets/avatar/men/white.svg)`,
                      backgroundSize: `cover`,
                      backgroundPosition: "center"
                    }}
                  />
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export const HeaderContainer: React.FC<ContainerProps> = props => (
  <HeaderWrapper {...props} />
)

export const Header = connector(HeaderContainer)
