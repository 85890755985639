import { Link } from "gatsby"
import React from "react"
import { connector, ContainerProps } from "./containers/AudioBtn.containers"

export type Props =
  | {
      as: "link"
      to: string
      onClick: (event?: any) => void
      onClickPlay: Function
    }
  | {
      as: "button"
      onClick: (event?: any) => void
      onClickPlay: Function
    }

export const AudioBtnWrapper: React.FC<Props> = ({
  onClickPlay,
  children,
  ...props
}) => {
  const onClick = async e => {
    if (onClickPlay) onClickPlay()
    if (props.onClick) props.onClick(e)
  }

  if (props.as === "link" && props.to)
    return (
      <Link {...props} to={props.to} onClick={onClick}>
        {children}
      </Link>
    )

  return (
    <button {...props} onClick={onClick}>
      {children}
    </button>
  )
}

export const AudioWrapper: React.FC<{ onClickPlay: Function }> = ({
  onClickPlay,
  children,
}) => {
  const onClick = async e => {
    if (onClickPlay) onClickPlay()
  }

  return <div onClick={onClick}>{children}</div>
}

export const AudioBtnContainer: React.FC<ContainerProps> = props => (
  <AudioBtnWrapper {...props} />
)

export const AudioContainer: React.FC<ContainerProps> = props => (
  <AudioWrapper {...props} />
)

export const AudioBtn = connector(AudioBtnContainer)
export const Audio = connector(AudioContainer)
