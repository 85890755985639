import React, { useRef } from "react"
import { Transition } from "@headlessui/react"
import { FormattedMessage } from "react-intl"
import logo from "../../assets/logo/logo.svg"
import { connector, ContainerProps } from "./containers/LockedModal.container"

export const LockedModalWrapper: React.FC<{
  isOpen: boolean
  onClose: () => void
  onSubmit: (email: string) => void
}> = ({ isOpen, onClose, onSubmit }) => {
  const ref = useRef<HTMLInputElement>(null)

  return (
    <Transition show={isOpen} className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          className="fixed inset-0 z-10"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 transition-opacity"
            onClick={() => onClose()}
            aria-hidden="true"
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
        </Transition.Child>

        <span
          className="hidden select-none sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <Transition.Child
          className="relative z-20 inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6"
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-8"
          enterTo="opacity-100 translate-y-0"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-8"
        >
          <div>
            <div className="mt-3 text-center sm:mt-2">
              <img src={logo} className="w-10 h-10 mx-auto" alt="logo" />
              <h3
                className="mt-4 text-xl font-bold leading-6 text-gray-800"
                id="modal-headline"
              >
                <FormattedMessage id="modal/locked/title"></FormattedMessage>
              </h3>
              <div className="mt-2">
                <p className="text-base text-gray-500">
                  <FormattedMessage id="modal/locked/description"></FormattedMessage>
                </p>
              </div>
            </div>
          </div>
          <form
            className="flex justify-center mt-5 space-x-2 sm:mt-6"
            onSubmit={event => {
              event.preventDefault()
              ref.current?.value && onSubmit(ref.current.value)
            }}
          >
            <input
              type="email"
              ref={ref}
              required
              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="email@example.com"
            />

            <button
              type="submit"
              className="inline-flex items-center px-10 py-2 text-sm font-medium text-white uppercase bg-green-500 border border-transparent rounded-md shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
              <FormattedMessage id="modal/locked/submit"></FormattedMessage>
            </button>
          </form>
        </Transition.Child>
      </div>
    </Transition>
  )
}

export const LockedModalContainer: React.FC<ContainerProps> = props => (
  <LockedModalWrapper {...props} />
)

export const LockedModal = connector(LockedModalContainer)
