import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { SoundsType } from "../../../redux/sounds/types"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props) => ({ ...props })

const mapDispatch = (
  dispatch: any,
  props: { name: keyof SoundsType }
) => ({
  onClickPlay: () => {
    dispatch(actions.sounds.play(props.name))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
