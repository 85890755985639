import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../../redux/actions"
import { RootState } from "../../../../redux/store"

const mapState = (state: RootState) => {
  const form = state.auth.flows.forgotValidation.form

  return {
    current: state.auth.flows.forgotValidation.steps.current,
    steps: state.auth.flows.forgotValidation.steps.number,
    next: true,
    succeed: state.auth.flows.forgotValidation.process.succeed,
    previous: state.auth.flows.forgotValidation.steps.current > 0,
    loading: state.auth.flows.forgotValidation.process.fetching,
    focus: form.password.focus,
    hidden:
      !!state.auth.flows.forgotValidation.process.error ||
      state.auth.flows.forgotValidation.process.succeed,
  }
}

const mapDispatch = (dispatch: any, props: { code: string }) => ({
  onNext: () => {
    dispatch(actions.auth.flows.forgotValidation.submit.execute(props.code))
  },
  onPrevious: () => {
    dispatch(actions.auth.flows.forgotValidation.steps.previous())
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
