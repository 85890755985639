import React from "react"
import { Transition } from "@headlessui/react"
import { useLocation } from "@reach/router"
import { connector, ContainerProps } from "./containers/Drawer.container"
import * as Components from "./components"
import { UserEntity } from "../../entities/UserEntity"

import logo from "../../assets/logo/logo.svg"

export type DrawerProps = {
  onClose?: Function
  onGo?: Function
  onLogout?: Function
  isAuthenticated: boolean
  user: UserEntity
}

export const DrawerWrapper: React.FC<DrawerProps> = ({
  isAuthenticated,
  user,
  onClose = () => false,
  onGo = () => false,
  onLogout = () => false,
}) => {
  const location = useLocation()

  const isOpen = location.href?.includes("menu=open") || false

  return (
    <Transition
      show={isOpen}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      className="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-right"
    >
      <div
        className="absolute min-h-screen w-full left-0 top-0 bg-transparent"
        onClick={() => onClose()}
      />
      <div className="relative rounded-lg shadow-lg max-w-md ml-auto">
        <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5 space-y-6">
            <div className="flex items-center justify-between">
              <div>
                <img className="h-8 w-auto" src={logo} alt="Futoshiki" />
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  onClick={() => onClose()}
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div>
              <nav className="grid gap-6">
                <Components.Item
                  title="Challenge quotidien"
                  to="/app/challenges/"
                  onClick={() => onGo("/app/challenges/")}
                />
                <Components.Item
                  title="Mode classique"
                  to="/app/classical/"
                  onClick={() => onGo("/app/classical/")}
                />
                <Components.Item
                  title="Tutoriel"
                  to="/app/tutorial/"
                  onClick={() => onGo("/app/tutorial/")}
                />
                <Components.Item
                  title="Statistiques & Succès"
                  to="/app/stats/"
                  onClick={() => onGo("/app/stats/")}
                />

                {isAuthenticated && user && (
                  <Components.Item
                    title="Paramètres"
                    to="/app/parameters/"
                    onClick={() => onGo("/app/parameters/")}
                  />
                )}
              </nav>
            </div>
          </div>

          <div className="py-6 px-5 space-y-6">
            {!isAuthenticated && (
              <div className="space-y-6">
                <span className="w-full flex rounded-md shadow-sm">
                  <span
                    onClick={() => onGo("/app/signup/")}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900 transition ease-in-out duration-150 cursor-pointer"
                  >
                    Créer un compte
                  </span>
                </span>
                <p className="text-center text-base leading-6 font-medium text-gray-500">
                  Déjà utilisateur ?{" "}
                  <span
                    onClick={() => onGo("/app/signin/")}
                    className="ml-2 underline cursor-pointer font-bold text-blue-600 hover:text-blue-800 transition ease-in-out duration-150"
                  >
                    Se connecter
                  </span>
                </p>
              </div>
            )}
            {isAuthenticated && (
              <div className="space-y-6">
                <span className="w-full flex rounded-md shadow-sm">
                  <button
                    onClick={() => onLogout()}
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900 transition ease-in-out duration-150"
                  >
                    Se déconnecter
                  </button>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Transition>
  )
}

export const DrawerContainer: React.FC<ContainerProps> = props => (
  <DrawerWrapper {...props} />
)

export const Drawer = connector(DrawerContainer)
