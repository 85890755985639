import { useLocation } from "@reach/router"
import React, { useEffect } from "react"
import { connector, ContainerProps } from "./containers/App.container"

export type AppProps = {
  onMount?: Function
}

export const AppWrapper: React.FC<AppProps> = ({
  onMount = () => false,
  children,
}) => {
  useEffect(() => {
    setTimeout(() => onMount())
  }, [])

  return <>{children}</>
}

export const AppContainer: React.FC<ContainerProps> = props => (
  <AppWrapper {...props} />
)

export const App = connector(AppContainer)
