import { Transition } from "@headlessui/react"
import React, { ReactElement, useEffect } from "react"
import { ContainerProps, connector } from "./containers/Success.containers"
import { AudioBtn } from "../AudioBtn/AudioBtn"

type Props = {
  onCancel?: Function
  onSubmit?: Function
  title: ReactElement
  isOpen: boolean
  isLoading: boolean
  description?: ReactElement
  onOpen?: Function
  label?: {
    submit?: ReactElement
    cancel?: ReactElement
  }
}

export const SuccessWrapper: React.FC<Props> = ({
  isOpen,
  onSubmit = () => false,
  isLoading,
  label,
  title,
  description,
  onOpen = () => false,
}) => {
  useEffect(() => {
    if (isOpen) onOpen()
  }, [isOpen])

  return (
    <>
      <Transition
        show={isOpen}
        className="fixed inset-0 z-20 transition-opacity"
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </Transition>

      <Transition
        show={isOpen}
        className="fixed inset-0 z-30 overflow-y-auto transform"
        enter="ease-out duration-300"
        enterFrom="opacity-0 translate-y-8"
        enterTo="opacity-100 translate-y-0"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-8 sm:translate-y-0"
      >
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          ></span>
          &#8203;
          <div
            className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="">
              <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-green-100 rounded-full">
                {/* <!-- Heroicon name: exclamation --> */}
                <svg
                  className="w-6 h-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div className="mt-3 text-center">
                <div
                  className="text-lg font-medium leading-6 text-gray-900"
                  id="modal-headline"
                >
                  {title}
                </div>
                {description && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-5">
              <AudioBtn
                name="complete"
                type="button"
                onClick={() => (!isLoading ? onSubmit() : false)}
                className={`relative inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${
                  isLoading
                    ? "pl-10 bg-green-300 cursor-not-allowed"
                    : "bg-green-600 hover:bg-green-700"
                }`}
              >
                {isLoading && (
                  <svg
                    className="absolute w-5 h-5 mr-3 -ml-1 text-white animate-spin left-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                {(label && label.submit) || "Valider"}
              </AudioBtn>
            </div>
          </div>
        </div>
      </Transition>
    </>
  )
}

export const SuccessContainer: React.FC<ContainerProps> = props => (
  <SuccessWrapper {...props} />
)

export const Success = connector(SuccessContainer)
