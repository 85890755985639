import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props) => ({
  ...props,
  to: props.to ? props.to : state.play.isChallenge ? "/app/challenges/" : props.to,
})

const mapDispatch = (dispatch: any) => ({})

export const connector = connect(mapState, null)
export type ContainerProps = ConnectedProps<typeof connector>
