import { RouteComponentProps } from "@reach/router"
import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props) => ({
  matrix: state.play.actual?.matrix,
  size: state.play.actual?.size,
  win: state.play.win,
})

const mapDispatch = (
  dispatch: any,
  props: RouteComponentProps & {
    id?: string
    isChallenge?: boolean
    level?: string
  }
) => ({
  onMount: () => {
    const { id, isChallenge = false, level } = props
    dispatch(actions.play.fetchSelectGame({ id, isChallenge, level }))
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
