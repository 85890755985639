import React, { useEffect } from "react"
import { GameHeaderBar } from "../GameHeaderBar/GameHeaderBar"
import { connector, ContainerProps } from "./containers/Play.containers"
import { RouteComponentProps } from "@reach/router"
import { GameEntity } from "../../entities/GameEntity"
import { FormattedMessage } from "react-intl"

import { Success } from "../Success/Success"
import { navigate } from "gatsby"
import { Grid } from "../Grid/Grid"
import { Controls } from "../Controls/Controls"
import { ControlsMobile } from "../ControlsMobile/ControlsMobile"
import { getSelection } from "../../utils/urls"
import { BackgroundShapes } from "../../modules/authentication/components/BackgroundShapes"

type Props = {
  matrix: GameEntity["matrix"]
  size: string
  onMount: Function
  win: boolean
} & RouteComponentProps

const PlayWrapper: React.FC<Props> = ({ matrix, onMount, win, size }) => {
  useEffect(() => {
    onMount()
  }, [])

  const home = getSelection()

  return (
    <div className="bg-gray-100 min-h-screen">
      <GameHeaderBar to="/app/classical/" startDate={new Date()} />
      <BackgroundShapes step={0} />

      <div className="container relative m-auto flex sm:items-center justify-center max-w-2xl">
        <div className="w-full">
          <div className="flex justify-center">
            <div className="w-full">
              {matrix && <Grid matrix={matrix} size={size} />}
            </div>
            <div className="hidden sm:block pl-10 flex-shrink-0">
              <Controls />
            </div>
          </div>
        </div>
      </div>

      <div className="sm:hidden fixed bottom-0 left-0 w-full">
        <ControlsMobile />
      </div>

      <Success
        isLoading={false}
        playSoundWhenOpen
        isOpen={win}
        title={<FormattedMessage id="play.success.title" />}
        description={<FormattedMessage id="play.success.description" />}
        label={{ submit: <FormattedMessage id="play.success.submit" /> }}
        onSubmit={() => navigate(home)}
      />
    </div>
  )
}

export const PlayContainer: React.FC<
  ContainerProps & RouteComponentProps
> = props => <PlayWrapper {...props} />

export const Play = connector(PlayContainer)
